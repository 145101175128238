.invalid-feedback {
  @apply mt-1 hidden w-full text-xs text-[#dc3545];
}
.is-invalid ~ .invalid-feedback {
  @apply block;
}

.field-wrapper label {
  @apply absolute -left-[10px] -top-[10px] ml-[2px] bg-[length:100%_10px] bg-center bg-no-repeat px-[3px] text-[12px] font-normal text-[#999] opacity-0 transition-all duration-100 ease-linear;
  background-image: linear-gradient(to bottom, #fff, #fff);
}
.field-wrapper label.show {
  @apply -left-[5px] -top-[17px] bg-transparent opacity-100;
}

.form-control {
  @apply block w-full appearance-none font-normal leading-normal placeholder:text-[#6C757D];
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control,
.form-select {
  @apply rounded-none !border-0 outline-none;
}

.form-select {
  @apply block w-full cursor-pointer appearance-none bg-white bg-[url('/images/icons/chevron-down.svg')] bg-[length:16px_12px] bg-[center_right_.75rem] bg-no-repeat p-1 text-sm font-normal leading-normal text-[#212529];
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.field-wrapper {
  @apply relative mb-[20px] block py-[5px];
}

.field-wrapper input.form-control:focus,
.field-wrapper .form-select:focus,
.field-wrapper textarea.form-control:focus {
  @apply !border-b-2 !border-solid !border-[#cc4141] !shadow-none;
}
.field-wrapper input.form-control,
.field-wrapper textarea.form-control {
  @apply rounded-none border-0 !border-b-[1px] !border-solid !border-[#ccc] bg-transparent py-[6px] pr-[12px] text-[1em] text-[#333] shadow-none;
}
.field-wrapper .form-select {
  @apply !border-b-[1px] !border-solid !border-[#ccc];
}
